import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Modal from "react-bootstrap/Modal";
import { filesService } from "../services";

const UploadButton: React.FC<{
  refreshFileList: () => void;
}> = ({ refreshFileList }) => {
  const fileSelector = useRef<HTMLInputElement>(null);
  const [msg, setMsg] = useState<string>();
  const [showProgessDialog, setShowProgressDialog] = useState<boolean>(false);
  const handleFiles = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files) {
      try {
        setMsg("Fetching upload URL...");
        setShowProgressDialog(true);
        const uploadUrl = await filesService.getUploadUrl(
          event.target.files[0]
        );
        setMsg(`Uploading ${event.target.files[0].name}...`);
        const result = filesService.uploadFile(
          event.target.files[0],
          uploadUrl
        );
        setMsg(await result);
      } catch (e) {
        console.log(e);
        setMsg("Error");
      }
    }
  };
  return (
    <>
      <Modal
        show={showProgessDialog}
        onHide={() => {
          setShowProgressDialog(false);
          refreshFileList();
        }}
        centered
      >
        <Modal.Header closeButton>Uploading File...</Modal.Header>
        <Modal.Body>{msg}</Modal.Body>
      </Modal>
      <FormControl
        hidden={true}
        ref={fileSelector}
        type="file"
        onChange={handleFiles}
      />
      <Button onClick={() => fileSelector.current?.click()}>Upload</Button>
    </>
  );
};

export default UploadButton;
