import React from "react";
import Modal from "react-bootstrap/Modal";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import { filesService } from "../services";

const ConfirmDeleteModal: React.FC<{
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentFileName: string;
  refreshFileList: () => void;
}> = ({ showModal, setShowModal, currentFileName, refreshFileList }) => {
  const deleteFile = async () => {
    await filesService.deleteFile(currentFileName);
    refreshFileList();
    setShowModal(false);
  };

  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>Delete File</Modal.Header>
      <Modal.Body>
        <Stack gap={3}>
          <div>Are you sure you want to delete this file?</div>
          <Button variant="danger" onClick={() => deleteFile()}>
            Delete
          </Button>
        </Stack>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDeleteModal;
