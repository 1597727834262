import { useState, useEffect, useRef } from "react";
import { Replay } from "vimond-replay";
import "vimond-replay/index.css";
import {
  Row,
  Col,
  Stack,
  Form,
  Container,
  Offcanvas,
  Navbar,
  ToggleButton,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { UploadButton, RenameModal, FileTree } from "../components";
import { FileIndex, UserSession } from "../types";
import { filesService } from "../services";
import ConfirmDeleteModal from "../components/confirmDeleteModal";
import MediaFilter from "../components/mediaFilter";
import { VideoStreamState } from "vimond-replay/default-player/Replay";

function FilesPage() {
  const [sessionLoaded, setSessionLoaded] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<FileIndex>();
  const [fileContent, setFileContent] = useState<string>("");
  const [fileUrl, setFileUrl] = useState<string>();
  const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [fileList, setFileList] = useState<FileIndex[]>([]);
  const [filteredList, setFilteredList] = useState<FileIndex[]>([]);
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false);
  const playbackState = useRef<VideoStreamState>({});

  const [userSession, setUserSession] = useState<UserSession>({
    autoplay: false,
    playedIndices: [],
    searchText: "",
    selectedFileIndex: -1,
    shuffle: false,
    typeFilter: "all",
    playedSeconds: 0,
  });

  const saveUserSession = async (session: UserSession) => {
    if (sessionLoaded) {
      await filesService.saveUserSession(session);
    }
  };

  const updateUserSession = async (session: UserSession) => {
    if (sessionLoaded) {
      await saveUserSession(session);
      setUserSession(session);
    }
  };

  const refreshFileList = async () => {
    const files = await filesService.listFiles();
    setFileList(files);
  };

  const fetchUserSession = async () => {
    const session = await filesService.getUserSession();
    setUserSession(session);
    setSessionLoaded(true);
  };

  const selectItem = (
    idx: number,
    item: FileIndex | undefined,
    skipSave = false
  ) => {
    if (sessionLoaded && item) {
      setSelectedItem(item);
      setShowMobileNav(false);
      if (!skipSave) {
        updateUserSession({
          ...userSession,
          selectedFileIndex: idx,
          playedSeconds: 0,
        });
      }
    }
  };

  const randomIndex = () => {
    return Math.floor(Math.random() * (filteredList.length - 0 + 1));
  };

  const autoselectNext = (force_shuffle = false) => {
    if (filteredList.length > 0) {
      if (force_shuffle || userSession.shuffle) {
        var idx = randomIndex();
        while (userSession.playedIndices.includes(idx)) {
          idx = randomIndex();
        }
        const newItem = filteredList[idx];
        selectItem(idx, newItem, true);
        updateUserSession({
          ...userSession,
          selectedFileIndex: idx,
          playedIndices: [...userSession.playedIndices, idx],
          playedSeconds: 0,
        });
      } else {
        const idx =
          filteredList.findIndex(
            (item) => item.fileName === selectedItem!.fileName
          ) + 1;
        const newItem = filteredList[idx];
        updateUserSession({
          ...userSession,
          selectedFileIndex: idx,
          playedSeconds: 0,
        });
        selectItem(idx, newItem, true);
      }
    }
  };

  useEffect(() => {
    async function getFile() {
      if (selectedItem) {
        if (["video", "image"].indexOf(selectedItem.fileType) >= 0) {
          setFileUrl(await filesService.getFileUrl(selectedItem.fileName));
          setFileContent("");
        } else if (selectedItem.fileType === "text") {
          setFileUrl("");
          setFileContent(await filesService.getFile(selectedItem.fileName));
        } else {
          console.log("unhandled", selectedItem);
        }
      }
    }
    getFile();
  }, [selectedItem]);

  useEffect(() => {
    refreshFileList();
    fetchUserSession();
  }, []);

  useEffect(() => {
    if (sessionLoaded) {
      var filteredFiles = fileList;
      if (userSession.typeFilter !== "all") {
        filteredFiles = filteredFiles.filter(
          (x) => x.fileType === userSession.typeFilter
        );
      }
      if (userSession.searchText !== "") {
        filteredFiles = filteredFiles.filter(
          (x) =>
            x.displayName
              .toUpperCase()
              .indexOf(userSession.searchText.toUpperCase()) > -1
        );
      }
      const sortedFiles = filteredFiles.sort((a, b) =>
        a.displayName.toLowerCase() < b.displayName.toLowerCase() ? -1 : 1
      );
      setFilteredList(sortedFiles);
      if (selectedItem === undefined && userSession.selectedFileIndex >= 0) {
        selectItem(
          userSession.selectedFileIndex,
          sortedFiles[userSession.selectedFileIndex],
          true
        );
      }
    }
  }, [userSession, fileList]);

  // useEffect(() => {
  //   const handleClose = () => {
  //     if (videoPlayer !== undefined) {
  //       updateUserSession({
  //         ...userSession,
  //         playedSeconds: videoPlayer.getCurrentTime(),
  //       });
  //     }
  //   };
  //   window.addEventListener("beforeunload", handleClose);
  // }, []);

  return (
    <>
      <RenameModal
        showModal={showRenameModal}
        setShowModal={setShowRenameModal}
        currentDisplayName={selectedItem?.displayName as string}
        currentFileName={selectedItem?.fileName as string}
        refreshFileList={refreshFileList}
      />
      <ConfirmDeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        currentFileName={selectedItem?.fileName as string}
        refreshFileList={refreshFileList}
      />
      <Container fluid className="h-100">
        <Row xs="1" className="flex-shrink-0">
          <Navbar expand="sm" expanded={showMobileNav}>
            <Container>
              <Navbar.Brand>{selectedItem?.displayName}</Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-sm`}
                onClick={() => setShowMobileNav(!showMobileNav)}
              />
              <Navbar.Offcanvas placement="end">
                <Offcanvas.Header closeButton>
                  <Form.Control
                    placeholder="Search"
                    style={{ width: "200px" }}
                    value={userSession.searchText}
                    onChange={(e) =>
                      updateUserSession({
                        ...userSession,
                        searchText: e.target.value,
                      })
                    }
                  />
                  <MediaFilter
                    userSession={userSession}
                    updateUserSession={updateUserSession}
                  />
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0">
                  <Container fluid>
                    <Row>
                      <Stack direction="horizontal" gap={2}>
                        <span className="ms-auto" />
                        <ToggleButton
                          type="checkbox"
                          checked={userSession.autoplay}
                          value="1"
                          variant="outline-primary"
                          onClick={() =>
                            updateUserSession({
                              ...userSession,
                              autoplay: !userSession.autoplay,
                            })
                          }
                        >
                          Autoplay
                        </ToggleButton>
                        <ToggleButton
                          type="checkbox"
                          value="1"
                          checked={userSession.shuffle}
                          variant="outline-primary"
                          onClick={() => {
                            if (!userSession.shuffle) {
                              updateUserSession({
                                ...userSession,
                                autoplay: true,
                                shuffle: true,
                              });
                              autoselectNext(true);
                              setShowMobileNav(false);
                            } else {
                              updateUserSession({
                                ...userSession,
                                shuffle: false,
                              });
                            }
                          }}
                        >
                          Shuffle
                        </ToggleButton>
                        <DropdownButton title="Edit">
                          <Dropdown.Item
                            onClick={() => setShowRenameModal(true)}
                          >
                            Rename
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => setShowDeleteModal(true)}
                          >
                            Delete
                          </Dropdown.Item>
                        </DropdownButton>
                        <UploadButton refreshFileList={refreshFileList} />
                      </Stack>
                    </Row>
                    <Row className="d-inline d-md-none w-100 m-0">
                      <div className="pt-2 px-0">
                        <FileTree
                          fileList={filteredList}
                          selectedItem={selectedItem}
                          setSelectedItem={selectItem}
                        />
                      </div>
                    </Row>
                  </Container>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </Row>
        <Row className="flex-fill h-100" style={{ overflowY: "scroll" }}>
          <Col
            lg="3"
            className="h-100 d-none d-md-inline"
            style={{ overflowY: "scroll" }}
          >
            <Row>
              <Col>
                <Form.Control
                  placeholder="Search"
                  className="m-1 p-1"
                  value={userSession.searchText}
                  onChange={(e) =>
                    updateUserSession({
                      ...userSession,
                      searchText: e.target.value,
                    })
                  }
                />
              </Col>
              <Col lg="4">
                <MediaFilter
                  userSession={userSession}
                  updateUserSession={updateUserSession}
                />
              </Col>
            </Row>
            <FileTree
              fileList={filteredList}
              selectedItem={selectedItem}
              setSelectedItem={selectItem}
            />
          </Col>
          <Col className="h-100" xs={true}>
            <Container className="mh-100 mw-100">
              <Row>
                <Col style={{ maxHeight: "80vh" }}>
                  {selectedItem === undefined ? (
                    <div>Nothing selected</div>
                  ) : selectedItem.fileType === "image" ? (
                    <img
                      alt="Fetching..."
                      src={fileUrl}
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "scale-down",
                      }}
                    />
                  ) : selectedItem.fileType === "video" ? (
                    <Replay
                      source={{
                        streamUrl: fileUrl || "",
                        startPosition: userSession.playedSeconds,
                      }}
                      initialPlaybackProps={{ isPaused: !userSession.autoplay }}
                      options={{
                        controls: {
                          includeControls: [
                            "playPauseButton",
                            "timeline",
                            "timeDisplay",
                            "volume",
                            "fullscreenButton",
                            "airPlayButton",
                          ],
                        },
                      }}
                      onStreamStateChange={(changedState: VideoStreamState) => {
                        playbackState.current = {
                          ...playbackState.current,
                          ...changedState,
                        };
                        if (
                          changedState.position !== 0 &&
                          playbackState.current.duration &&
                          playbackState.current.duration > 0 &&
                          playbackState.current.playState !== "starting" &&
                          playbackState.current.playState !== "inactive" &&
                          playbackState.current.isPaused === true
                        ) {
                          if (
                            playbackState.current.position ===
                            playbackState.current.duration
                          ) {
                            playbackState.current = {
                              ...playbackState.current,
                              position: -1,
                            };
                            autoselectNext();
                          } else if (playbackState.current.position) {
                            saveUserSession({
                              ...userSession,
                              playedSeconds: playbackState.current.position,
                            });
                          }
                        }
                      }}
                    />
                  ) : selectedItem.fileType === "text" ? (
                    <div className="h-100" style={{ overflowY: "scroll" }}>
                      <h3>{selectedItem.displayName}</h3>
                      <body
                        style={{
                          whiteSpace: "pre-line",
                          paddingBottom: "50px",
                        }}
                      >
                        {fileContent}
                      </body>
                    </div>
                  ) : (
                    <div>{selectedItem.displayName}</div>
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default FilesPage;
